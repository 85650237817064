<template>
  <ServiceLayout>
    <v-sheet width="370" rounded>
      <v-form @submit.prevent="confirmNewPassword" ref="form" lazy-validation>
        <v-stepper v-model="step">
          <v-stepper-items>
            <v-stepper-content step="1">
              <h6 class="text-h6 text-center mb-7">Resetowanie hasła</h6>
              <v-text-field
                :rules="$rules.required"
                v-model="email"
                outlined
                dense
                label="Email"
                type="email"
                name="email"
              />
              <v-btn
                class="mb-1"
                :loading="pending"
                @click="sendCode"
                large
                rounded
                block
                color="primary"
                >Wyślij kod</v-btn
              >
            </v-stepper-content>
            <v-stepper-content step="2">
              <h6 class="text-h6 text-center mb-7">Resetowanie hasła</h6>
              <v-text-field
                outlined
                dense
                label="Wprowadź kod"
                :rules="$rules.required"
                v-model="code"
              />
              <v-btn
                :loading="pending"
                @click="confirmCode"
                class="mb-1"
                large
                rounded
                block
                color="primary"
                >Resetuj hasło</v-btn
              >
              <v-btn
                @click="repeatCodeSend"
                block
                class="mt-4 text-subtitle-2"
                text
                plain
                :disabled="isCodeSent"
                >Wyślij kod ponownie</v-btn
              >
            </v-stepper-content>
            <v-stepper-content step="3">
              <h6 class="text-h6 text-center mb-7">Ustawienie hasła</h6>
              <RepeatPassword ref="passwords" />
              <v-btn
                :loading="pending"
                class="mb-1"
                type="submit"
                large
                rounded
                block
                color="primary"
                >Ustaw hasło</v-btn
              >
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
    </v-sheet>
  </ServiceLayout>
</template>

<script>
import RepeatPassword from '@/components/UI/RepeatPassword'
import ServiceLayout from '@/layouts/ServiceLayout'

import RepositoryFactory from '@/repositories/RepositoryFactory'
import {mapActions} from 'vuex'

const ForgotPasswordRepository = RepositoryFactory.get('forgotPassword')

export default {
  name: 'ResetPassword',
  components: {
    ServiceLayout,
    RepeatPassword,
  },
  data() {
    return {
      step: 1,
      pending: false,
      isCodeSent: false,
      codeConfirmed: false,
      email: '',
      code: null,
    }
  },
  methods: {
    ...mapActions(['showNotification']),
    async repeatCodeSend() {
      const timeout = 15_000
      try {
        this.isCodeSent = true
        await ForgotPasswordRepository.sendCode(this.email)
        setTimeout(() => {
          this.isCodeSent = false
        }, timeout)
        this.showNotification({
          type: 'success',
          message: 'NOTY_CODE_SENT',
        })
      } catch (e) {
        this.isCodeSent = false
      }
    },
    async sendCode() {
      try {
        this.pending = true
        await ForgotPasswordRepository.sendCode(this.email)
        this.pending = false
        this.step += 1
      } catch {
        this.pending = false
      }
    },
    async confirmCode() {
      const {email, code} = this
      if (code) {
        try {
          this.pending = true
          await ForgotPasswordRepository.verifyCode({email, code})
          this.step += 1
        } catch (e) {
          this.showNotification({
            type: 'error',
            message: e.response.data,
          })
          this.code = null
        }
        this.pending = false
      }
    },
    async confirmNewPassword() {
      try {
        const passwords = await this.$refs.passwords.validate()
        this.pending = true
        await ForgotPasswordRepository.newPasswordConfirm({
          email: this.email,
          code: this.code,
          password: passwords.password,
          confirmPassword: passwords.passwordConfirmation,
        })
        this.pending = false
        this.$router.push('/login')
      } catch (e) {
        this.pending = false
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
